import React from "react";
import classNames from "classnames";
import styles from "./PreferencesNoticeUi.module.css";

export const PreferencesNoticeUi = ({ className, ...props }) => {
  return (
    <div className={classNames(styles.notice, className)} {...props}>
      Your selections will be saved to your Marriott Bonvoy profile to provide
      tailored offers and personalized experiences.
    </div>
  );
};
