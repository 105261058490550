import React, { useEffect } from "react";
import classNames from "classnames";
import styles from "./VideoContainer.module.css";
import { createRoot } from 'react-dom/client';
import ReactPlayerLoader from '@brightcove/react-player-loader';


export const VideoContainer = ({ className, src, poster, ...rest }) => {
  return (
    <div
      className={classNames(styles.container, className)}
      {...rest}
      dangerouslySetInnerHTML={{
        __html: `
    <video
        class="${styles.video}"
        src="${src}"
        poster="${poster}"
        preload="auto"
        autoplay
        muted
        loop
        playsinline
      />
    `,
      }}
    />
  );
};

const onSuccess = function(success) {
  const player = success.ref?.player();
  player.controlBar.hide();
};

let playerRoot;
export const BrightcoveVideoContainer = ({ className, videoId, ...rest }) => {

  useEffect(() => {
      playerRoot = createRoot(document.getElementById('player-container'));
  }, []);

    useEffect(() => {
      playerRoot.render(
        <div className={classNames(styles.container, className)} {...rest}>
        <ReactPlayerLoader accountId='6051814381001' videoId={videoId}  
        options={{
          autoplay: true,
          controls: false,
          loop: true,
          muted: true,
          playsInline: true,
          responsive: true,
        }} 
        onSuccess={onSuccess}
        attrs={{className: styles.video}}
        />
</div>
      );

    }, [videoId]);
  return (
    <div id="player-container"></div>
  );
};