
export const surveyData = [
  {
    passionName: "golf",
    iconName: "golf",
    videoId: '6370270756112',
  },
  {
    passionName: "music",
    iconName: "music",
    videoId: '6370273191112',
  },
  {
    passionName: "arts & culture",
    iconName: "culture",
    videoId: '6370239381112',
  },
  {
    passionName: "beach",
    iconName: "beach",
    videoId: '6370268890112',
    },
 
  {
    passionName: "spa",
    iconName: "spa",
    videoId: '6370275889112',
  },
  {
    passionName: "culinary",
    iconName: "culinary",
    videoId: '6370279905112',
  },
  {
    passionName: "ski",
    iconName: "ski",
    videoId: '6346868427112',
  },
];
