import React from "react";
import { Icon } from "~/components";
import styles from "./ConfirmSaveBanner.module.css";

export const ConfirmSaveBanner = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <Icon name="success" />
        <span>Your selections have been saved to your profile</span>
      </div>
    </div>
  );
};
