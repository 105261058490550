import React, { useEffect, useState, useCallback } from "react";
import styles from "../../styles/follow/FollowCard.module.css";
import Checkmark from "../../assets/follow/icons/checkmark.svg";
import { useNavigate } from "react-router-dom";
import { exploreData } from "./explore-data";
import { usePassions } from "../../lib/hooks/use-passions";
import { useAcid } from "../../lib/hooks/use-acid";
import { sendAnalyticsSubmitEvent } from "../../utils/adobe-data-layer-utils";
import { BtnUi, SignInDialogUi } from "~/components";
import { useCampaignPage } from "~/store";

export const FollowCard = (props) => {
  const experienceType = "follow";
  const { title, images, passion, analyticsDesc } = props;
  const [isActive, setIsActive] = useState(false);
  const { acid } = useAcid();
  const {
    selectPassion,
    unselectPassion,
    isPassionSelected,
    savePassionsToServer,
    getSelectedPassionsAsFlatArray,
  } = usePassions(experienceType);

  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const { setShowPageLoader } = useCampaignPage();

  // Function to check if the slider is in the top half of the viewport
  const isInTopHalf = (element) => {
    if (!element) {
      return false;
    }

    const rect = element.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;

    return rect.top >= 0 && rect.bottom <= viewportHeight / 2;
  };

  // Function to handle scroll and check if the slider is in the top half
  const checkSliderPosition = () => {
    const slider = document.getElementById(passion);
    if (slider && isInTopHalf(slider)) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  // Add event listener on scroll and remove it when the component unmounts
  useEffect(() => {
    window.addEventListener("scroll", checkSliderPosition);
    checkSliderPosition(); // Initial check on load

    return () => {
      window.removeEventListener("scroll", checkSliderPosition);
    };
  }, []); // Empty array ensures the effect runs only on mount and unmount

  const navigate = useNavigate();

  // initializing following state based on session storage data
  const isFollowingCurrent = isPassionSelected(passion);
  const [following, setFollowing] = React.useState(isFollowingCurrent);

  let followClass = "";
  if (following) {
    followClass = styles.following;
  }

  const followButtonClickAction = async () => {
    if (following) {
      unfollowAction();
    } else {
      followAction();
    }
    await savePassionsToServer(acid);
  };

  const followAction = async () => {
    setFollowing(true);
    selectPassion(passion);
    // sending analytics data
    const selectedPassions = [passion];
    sendAnalyticsSubmitEvent(selectedPassions);
  };

  const unfollowAction = async () => {
    setFollowing(false);
    unselectPassion(passion);
  };

  const label = following ? "Following" : "Follow";

  const onCloseDialog = useCallback(
    () => setShowSignInDialog(false),
    [setShowSignInDialog],
  );

  const onSubmitDialog = useCallback(() => {
    onCloseDialog();
    setShowPageLoader(true);
  }, [onCloseDialog, setShowPageLoader]);

  const onExploreClick = useCallback(() => {
    if (!acid) {
      setShowSignInDialog(true);
      return;
    }

    navigate(`/follow/explore?passion=${passion}`);
  }, [setShowSignInDialog]);

  return (
    <>
      <div className={styles.card}>
        {/*<img src={image} alt="" />*/}
        <div
          id={passion}
          className={`${styles.slider} ${isActive ? styles.active : ""}`}
        >
          <div className={styles.slides}>
            {images.map((image, idx) => (
              <div className={styles.slide} key={idx}>
                <img src={image} alt="" />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.body}>
          <h2>{title}</h2>
          <p>{exploreData[passion].description}</p>
          <div className={styles.buttons}>
            <BtnUi
              className={`${followClass} custom_click_track`}
              data-custom_click_track_value={`Follow|${analyticsDesc}|internal`}
              onClick={followButtonClickAction}
            >
              {following && <img src={Checkmark} alt="" />}
              {label}
            </BtnUi>
            {following && (
              <BtnUi
                className="custom_click_track"
                data-custom_click_track_value={`Follow Explore|Explore ${analyticsDesc}|internal`}
                onClick={onExploreClick}
              >
                Explore
              </BtnUi>
            )}
          </div>
        </div>
      </div>
      {showSignInDialog && (
        <SignInDialogUi
          onClose={onCloseDialog}
          onSubmit={onSubmitDialog}
          selectedPassions={getSelectedPassionsAsFlatArray()}
          cid={experienceType}
        />
      )}
    </>
  );
};
