import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Heading, BtnUi, SignInDialogUi } from "~/components";
import { useCampaignPage } from "~/store";
import { usePassions } from "~/lib/hooks/use-passions";
import { useAcid } from "~/lib/hooks/use-acid";
import { BrightcoveVideoContainer } from "./VideoContainer";
import { Survey } from "./Survey";
import { sendAnalyticsSubmitEvent } from "~/utils/adobe-data-layer-utils";
import styles from "./VideoPassions.module.css";
import { surveyData as data } from "./surveyData";

const cid = "video";

export const VideoPassions = () => {
  const { acid } = useAcid();
  const navigate = useNavigate();
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const { setShowPageLoader } = useCampaignPage();
  const {
    selectPassion,
    unselectPassion,
    savePassionsToServer,
    getSelectedPassionsAsArray,
    getSelectedPassionsAsFlatArray,
  } = usePassions(cid);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const startSurvey = () => setCurrentIndex(0);
  const next = (action) => {
    const { passionId, passionName } = data[currentIndex];
    const nextIndex = currentIndex + 1;
    const nextPassion = data[nextIndex];
    const id = passionId ?? passionName.toLowerCase();

    // If "yes" clicked
    if (action === true) {
      selectPassion(id);
    }

    // If "no" clicked
    if (action === false) {
      unselectPassion(id);
    }

    if (nextPassion) {
      setCurrentIndex(nextIndex);
    } else {
      if (!acid) {
        setShowSignInDialog(true);
        return;
      }

      savePassionsToServer(acid);
      sendAnalyticsSubmitEvent(getSelectedPassionsAsArray());
      navigate("/video/explore");
    }
  };

  const onCloseDialog = useCallback(
    () => setShowSignInDialog(false),
    [setShowSignInDialog],
  );

  const onSubmitDialog = useCallback(() => {
    onCloseDialog();
    setShowPageLoader(true);
  }, [onCloseDialog, setShowPageLoader]);

  if (currentIndex >= 0) {
    return (
      <>
        <Survey data={data[currentIndex]} next={next} />
        {showSignInDialog && (
          <SignInDialogUi
            onClose={onCloseDialog}
            onSubmit={onSubmitDialog}
            selectedPassions={getSelectedPassionsAsFlatArray()}
            cid={cid}
          />
        )}
      </>
    );
  }

  return (
    <section className={styles.box}>
      <Heading
        type="h1"
        className={styles.header}
        text="Tap Into Your Travel Passions"
      />
      <p className={styles.subheader}>
        Give a thumbs up or down on interests and activities to get a more
        relevant experience.
      </p>
      <BrightcoveVideoContainer className={styles.media} videoId={data[0].videoId} />
      <BtnUi className={styles.btn} onClick={startSurvey}>
        Get Started
      </BtnUi>
    </section>
  );
};
