import { useSessionStorage } from "./use-session-storage";
import { useState } from "react";
import actions from "../../config.json";

export const usePassions = (experienceType) => {
  const storageKey = "storage";
  const { setItem, getItem } = useSessionStorage(experienceType, {
    key: storageKey,
    value: {
      golf: 0,
      beach: 0,
      ski: 0,
      culinary: 0,
      culture: 0,
      music: 0,
      spa: 0,
    },
  });
  const [passions, setPassions] = useState(getItem(storageKey));

  const selectPassion = (passion) => {
    const value = { ...getItem(storageKey) };
    value[passion] = 1;
    setItem(storageKey, value);
    setPassions(value);
  };

  const tapPassion = (passion, maxTaps = 2, val) => {
    const value = { ...getItem(storageKey) };
    let taps = value[passion];

    if (taps >= maxTaps) {
      taps = val ?? 0;
    } else {
      taps += val ?? 1;
    }

    value[passion] = taps;
    setItem(storageKey, value);
    setPassions(value);
    return taps;
  };

  const getPassionValue = (passion) => {
    return getItem(storageKey)[passion] ?? 0;
  };

  const unselectPassion = (passion) => {
    const value = { ...getItem(storageKey) };
    value[passion] = 0;
    setItem(storageKey, value);
    setPassions(value);
  };

  const isPassionSelected = (passion) => {
    return (getItem(storageKey)?.[passion] || 0) > 0;
  };

  const isAnyPassionSelected = () => {
    return Object.entries(passions).find(([, value]) => value > 0) != null;
  };

  const getSelectedPassionsAsArray = () => {
    const passions = getItem(storageKey);
    return Object.entries(passions)
      .filter(([, value]) => value > 0)
      .map(([key, value]) => {
        if (key === "golf" || key === "spa") {
          return value >= 2 ? `${key}-love` : `${key}-like`;
        }
        return key;
      });
  };

  const getSelectedPassionsAsFlatArray = () => {
    const passions = getItem(storageKey);
    return Object.entries(passions)
      .filter(([, value]) => value > 0)
      .map(([key]) => key);
  };

  const savePassionsToServer = async (acid) => {
    const passions = getItem(storageKey);

    const data = [
      {
        category: "GOLF_LOV",
        values: [],
      },
      {
        category: "SPA_LOV",
        values: [],
      },
      {
        category: "INTERESTS_AND_HOBBIES_LOV",
        values: [],
      },
    ];

    Object.entries(passions).forEach(([key, value]) => {
      if (value > 0) {
        switch (key) {
          case "golf":
            if (value > 1) {
              data[0].values.push("ENTHU");
            } else {
              data[0].values.push("CASUAL");
            }
            break;
          case "spa":
            if (value > 1) {
              data[1].values.push("ENTHU");
            } else {
              data[1].values.push("CASUAL");
            }
            break;
          case "beach":
            data[2].values.push("BCH");
            break;
          case "ski":
            data[2].values.push("WNTRACT");
            break;
          case "culinary":
            data[2].values.push("COOK");
            break;
          case "culture":
            data[2].values.push("CULTR");
            break;
          case "music":
            data[2].values.push("MUSIC");
            break;
          default:
        }
      }
    });

    const actionUrl = actions["marriott-passions-v3/save-passions"];

    try {
      await fetch(`${actionUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          acid: acid,
          passions: data,
        }),
      });
    } catch (err) {
      console.error(err);
    }
  };

  return {
    selectPassion,
    unselectPassion,
    tapPassion,
    isPassionSelected,
    isAnyPassionSelected,
    getPassionValue,
    getSelectedPassionsAsArray,
    getSelectedPassionsAsFlatArray,
    savePassionsToServer,
  };
};
