/* eslint-disable react/display-name */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import styles from "./InteractivePage.module.css";
import HeartFillIcon from "../../assets/common/heart-fill.svg";
import { usePassions } from "../../lib/hooks/use-passions";
import { useNavigate } from "react-router-dom";
import { useCampaignPage } from "~/store";
import { useAcid } from "../../lib/hooks/use-acid";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  pointerWithin,
  rectIntersection,
  useDroppable,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  rectSortingStrategy,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  sendAnalyticsSubmitEvent,
} from "../../utils/adobe-data-layer-utils";
import {
  Heading,
  BtnUi,
  PreferencesNoticeUi,
  SignInDialogUi,
} from "~/components";

const data = [
  {
    label: "Beach",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/si-nanti-snorkeling-35248",
    passion: "beach",
  },
  {
    label: "Culinary",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/fp-aklfp-wine-dinner-20235?wid=316&fit=constrain",
    passion: "culinary",
  },

  {
    label: "Winter Sports",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/tx-szgjf-skiing-31248?wid=316&fit=constrain",
    passion: "ski",
  },
  {
    label: "Golf",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/mc-wasbd-golf-course-35141",
    passion: "golf",
  },
  {
    label: "Music",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/mc-tricc-jazz-musicians-23095?wid=316&fit=constrain",
    passion: "music",
  },
  {
    label: "Spa",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/50514591-espa_privatespasuite_outdoor_sized_tile?wid=316&fit=constrain",
    passion: "spa",
  },
  {
    label: "Arts & Culture",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/cy-sfobc-cable-car-with-alcatraz-23185",
    passion: "culture",
  },
];

const SortableCard = React.memo(
  ({ data, onClick, isDocked = false, onRemove }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: data.id || data.passion,
      disabled: isDocked,
      transition: {
        duration: 150,
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
      },
      data: {
        type: "card",
        item: data,
        isDocked,
      },
    });

    // Assign a unique shape class based on the passion/id
    const getShapeClass = () => {
      if (isDocked) return ""; // No special shape for docked items
      
      const shapes = {
        beach: styles.shapeHorizontalOvalThick,
        culinary: styles.shapeHorizontalOvalThick,
        music: styles.shapeVerticalOvalThick,
        ski: styles.shapeVerticalOvalThin,
        golf: styles.shapeRoundedRectOpposite,
        spa: styles.shapeRound,
        culture: styles.shapeRoundedRect,
      };
      
      return shapes[data.passion] || "";
    };

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? 999 : 1,
      opacity: isDragging ? 0.8 : 1,
      willChange: "transform",
      ...(isDocked ? { width: "56px", height: "56px" } : {}),
    };

    // Handle remove button click
    const handleRemoveClick = (e) => {
      e.stopPropagation(); // Prevent triggering card click
      e.preventDefault(); // Prevent drag start
      if (onRemove) {
        onRemove(data);
      }
    };

    const handleRemoveMouseDown = (e) => {
      e.stopPropagation(); // Prevent triggering card click
    };

    return (
      <div
        ref={setNodeRef}
        style={style}
        {...(isDocked ? {} : attributes)}
        {...(isDocked ? {} : listeners)}
        className={`${styles.card} ${getShapeClass()} ${isDocked ? styles.dockedCard : ""} custom_click_track`}
        data-custom_click_track_value={`Travel|${data.label}|internal`}
        data-dragging={isDragging}
        onClick={isDocked ? undefined : onClick}
      >
        <img src={data.image} alt="Card image" loading="eager" />
        
        {isDocked && (
          <div 
            className={styles.removeButton} 
            onClick={handleRemoveClick}
            onMouseDown={handleRemoveMouseDown}
            onTouchStart={handleRemoveMouseDown}
            title="Remove from dock"
          >
            ×
          </div>
        )}
      </div>
    );
  },
);

const CardPreview = React.memo(({ data, isDocked }) => {
  // Assign a unique shape class based on the passion/id
  const getShapeClass = () => {
    if (isDocked) return ""; // No special shape for docked items
    
    const shapes = {
      beach: styles.shapeHorizontalOvalThick,
      culinary: styles.shapeHorizontalOvalThick,
      music: styles.shapeVerticalOvalThick,
      ski: styles.shapeVerticalOvalThin,
      golf: styles.shapeRoundedRectOpposite,
      spa: styles.shapeRound,
      culture: styles.shapeRoundedRect,
    };
    
    return shapes[data.passion] || "";
  };

  // Get the shape class to determine dimensions
  const shapeClass = getShapeClass();
  
  // Get the CSS properties from the shape class
  const getShapeStyle = () => {
    if (isDocked) return { width: "56px", height: "56px" };
    
    // Define explicit dimensions based on shape
    switch(data.passion) {
      case 'beach':
      case 'culinary':
        return { 
          width: '150px', 
          height: '80px',
          borderTopRightRadius: '50px',
          borderTopLeftRadius: '50px',
          borderBottomLeftRadius: '50px',
          borderBottomRightRadius: '50px'
        };
      case 'music':
        return { 
          width: '120px', 
          height: '200px',
          borderTopRightRadius: '75px',
          borderTopLeftRadius: '75px',
          borderBottomLeftRadius: '75px',
          borderBottomRightRadius: '75px'
        };
      case 'ski':
        return { 
          width: '70px', 
          height: '200px',
          borderTopRightRadius: '50px',
          borderTopLeftRadius: '50px',
          borderBottomLeftRadius: '50px',
          borderBottomRightRadius: '50px'
        };
      case 'golf':
        return { 
          width: '100px', 
          height: '150px',
          borderTopLeftRadius: '55px',
          borderBottomRightRadius: '55px'
        };
      case 'spa':
        return { 
          width: '100px', 
          height: '100px',
          borderRadius: '50%'
        };
      case 'culture':
        return { 
          width: '150px', 
          height: '100px',
          borderTopRightRadius: '55px',
          borderBottomLeftRadius: '55px'
        };
      default:
        return {};
    }
  };

  return (
    <div
      style={getShapeStyle()}
      className={`${styles.card} ${shapeClass} ${isDocked ? styles.dockedCard : ""} ${styles.draggingCard}`}
    >
      <img src={data.image} alt="Card image" loading="eager" />
      {!isDocked && (
        <div className={styles.cardLabelOverlay}>
          <div className={styles.cardLabelText}>
            {data.label}
          </div>
        </div>
      )}
      {isDocked && (
        <div className={styles.removeButton}>×</div>
      )}
    </div>
  );
});

const DroppableArea = React.memo(
  ({ id, children, className, dataEmptyText }) => {
    const { setNodeRef } = useDroppable({
      id,
      data: {
        type: "container",
        accepts: ["card"],
      },
    });

    return (
      <div
        ref={setNodeRef}
        id={id}
        className={className}
        data-empty-text={dataEmptyText}
      >
        {children}
      </div>
    );
  },
);

const experienceType = "interactive";

export const InteractivePage = () => {
  const navigate = useNavigate();
  const { acid } = useAcid();
  const {
    selectPassion,
    unselectPassion,
    isPassionSelected,
    isAnyPassionSelected,
    savePassionsToServer,
    getSelectedPassionsAsArray,
    getSelectedPassionsAsFlatArray,
  } = usePassions(experienceType);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const { setShowPageLoader } = useCampaignPage();
  const [items, setItems] = useState(
    data.map((item) => ({ ...item, id: `grid-${item.passion}` })),
  );
  const [dockedItems, setDockedItems] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  // Initialize dock with pre-selected passions
  useEffect(() => {
    // Get all data items that have selected passions
    const selectedItems = data
      .filter(item => isPassionSelected(item.passion))
      .map(item => ({
        ...item,
        id: `dock-${item.passion}`
      }));
    
    if (selectedItems.length > 0) {
      // Add selected items to dock
      setDockedItems(selectedItems);
      
      // Remove selected items from grid
      setItems(prevItems => 
        prevItems.filter(item => {
          // Extract passion from the id (format: grid-passion)
          const passion = item.id.replace('grid-', '');
          return !isPassionSelected(passion);
        })
      );
    }
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // Handle drag start event
  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
    setActiveItem(active.data.current?.item);
  };

  // Handle drag end event
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over) {
      setActiveId(null);
      setActiveItem(null);
      return;
    }

    console.log("Drag end:", { active, over });

    const activeData = active.data.current;
    const activeItem = activeData?.item;
    const activeIsDocked = activeData?.isDocked;

    // If dropped on the dock
    if (over.id === "dock" && !activeIsDocked) {
      console.log("Dropping on dock");
      // Check if dock is full (max 6 items)
      if (dockedItems.length >= 6) {
        console.log("Dock is full");
        setActiveId(null);
        setActiveItem(null);
        return;
      }

      // Add to dock
      const newDockedItem = {
        ...activeItem,
        id: `dock-${activeItem.passion}`,
      };

      console.log("Adding to dock:", newDockedItem);
      setDockedItems((prev) => [...prev, newDockedItem]);

      // Remove from grid
      setItems((prev) => prev.filter((item) => item.id !== active.id));
      
      // Select the passion when added to dock (favorites)
      selectPassion(activeItem.passion);
    }
    // Reordering within the dock
    else if (activeIsDocked && over.data.current?.isDocked) {
      console.log("Reordering within dock");
      setDockedItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        if (oldIndex !== -1 && newIndex !== -1) {
          return arrayMove(items, oldIndex, newIndex);
        }

        return items;
      });
    }
    // Reordering within the grid
    else if (
      !activeIsDocked &&
      !over.data.current?.isDocked &&
      over.id !== "dock" &&
      over.id !== "grid"
    ) {
      console.log("Reordering within grid");
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        if (oldIndex !== -1 && newIndex !== -1) {
          return arrayMove(items, oldIndex, newIndex);
        }

        return items;
      });
    }

    setActiveId(null);
    setActiveItem(null);
  };

  const handlePassionClick = (passion) => {
    if (isPassionSelected(passion)) {
      unselectPassion(passion);
    } else {
      selectPassion(passion);
    }
  };

  const handleSubmit = useCallback(async () => {
    if (!acid) {
      setShowSignInDialog(true);
      return;
    }

    sendAnalyticsSubmitEvent(getSelectedPassionsAsArray());
    await savePassionsToServer(acid);
    navigate("/interactive/explore");
  }, [
    acid,
    setShowSignInDialog,
    getSelectedPassionsAsArray,
    savePassionsToServer,
    navigate,
  ]);

  const onCloseDialog = useCallback(
    () => setShowSignInDialog(false),
    [setShowSignInDialog],
  );

  const onSubmitDialog = useCallback(() => {
    onCloseDialog();
    setShowPageLoader(true);
  }, [onCloseDialog, setShowPageLoader]);

  const sortableIds = useMemo(() => {
    return items.map((d) => d.id);
  }, [items]);

  const sortableDockedIds = useMemo(() => {
    return dockedItems.map((d) => d.id);
  }, [dockedItems]);

  const handleRemoveFromDock = (item) => {
    // Create a new grid item
    const newGridItem = {
      ...item,
      id: `grid-${item.passion}`,
    };
    
    // Add to grid
    setItems((prev) => [...prev, newGridItem]);
    
    // Remove from dock
    setDockedItems((prev) => prev.filter((i) => i.id !== item.id));
    
    // Unselect the passion when removed from dock (favorites)
    unselectPassion(item.passion);
    console.log("Removed from dock:", item);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={(args) => {
        const intersections = rectIntersection(args);
        if (intersections.length > 0) return intersections;
        return pointerWithin(args);
      }}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      measuring={{
        droppable: {
          strategy: "always",
        },
      }}
      autoScroll={{
        threshold: {
          x: 0.1,
          y: 0.1,
        },
        acceleration: 10,
        interval: 10,
      }}
    >
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div className={styles.banner}>
          <Heading type="h1" text="Select Your Favorites" />
          <p className={styles.subheader}>
          Drag and drop your favorite activities and experiences into the Favorites section below to unlock personalized recommendations and offers.
          </p>
        </div>      
        
        <DroppableArea id="grid" className={styles.cardGrid} >
          <SortableContext items={sortableIds} strategy={rectSortingStrategy}>
            {items.map((d) => (
              <SortableCard
                key={d.id}
                data={d}
                isSelected={isPassionSelected(d.passion)}
                onClick={() => handlePassionClick(d.passion)}
                isDocked={false}
              />
            ))}
          </SortableContext>
        </DroppableArea>

        <div className={styles.favoritesLabelContainer}>
          <div className={styles.favoritesLabel}>
            <img src={HeartFillIcon} alt="Favorites" />
            Favorites
          </div>
        </div>
        <DroppableArea
          id="dock"
          className={styles.imageDock}
          data-empty-text="Drag and drop your favorites here"
        >
          <SortableContext
            items={sortableDockedIds}
            strategy={horizontalListSortingStrategy}
          >
            {dockedItems.map((d) => (
              <SortableCard
                key={d.id}
                data={d}
                isSelected={isPassionSelected(d.passion)}
                isDocked={true}
                onRemove={handleRemoveFromDock}
              />
            ))}
          </SortableContext>
        </DroppableArea>

        <DragOverlay 
          adjustScale={false} 
          dropAnimation={null}
          zIndex={9999}
        >
          {activeId && activeItem ? (
            <CardPreview
              data={activeItem}
              isSelected={isPassionSelected(activeItem.passion)}
              isDocked={activeId.toString().startsWith("dock-")}
            />
          ) : null}
        </DragOverlay>

        <div className={styles.buttonContainer}>
          <BtnUi
            className={`${styles.explore} custom_click_track`}
            data-custom_click_track_value={`Travel Footer|Save Continue|internal`}
            disabled={!isAnyPassionSelected()}
            onClick={handleSubmit}
          >
            Save & Continue
          </BtnUi>
          <PreferencesNoticeUi />
        </div>
        {showSignInDialog && (
          <SignInDialogUi
            onClose={onCloseDialog}
            onSubmit={onSubmitDialog}
            selectedPassions={getSelectedPassionsAsFlatArray()}
            cid={experienceType}
          />
        )}
      </div>
    </DndContext>
  );
};
